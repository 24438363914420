export default class KeyWordLookUpRequest {
    keyWord: string;
    id: number | null;

    constructor(keyword: string, id: number | null) {
        this.keyWord = keyword;
        if (id && Number.isInteger(id)) {
            this.id = id;
        } else {
            this.id = -1;
        }
    }
}
