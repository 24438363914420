
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '../../../../lib/util'
    import AbpBase from '../../../../lib/abpbase'
    import ContactForm from "@/views/setting/companycontext/contact/contact-form.vue";
    import Contact from "@/store/entities/companycontext/contact";

    @Component({
        components: { ContactForm }
    })
    export default class CreateContact extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        contact: Contact = new Contact();

        visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            }else{
                (this.$refs.form as any).updateContact();
            }
        }

        cancel() {
            ((this.$refs.form as any).$refs.contactForm as any).resetFields();
            this.$emit('input', false);
        }

        save() {
            let contactForm = ((this.$refs.form as any).$refs.contactForm as any);
            (contactForm).validate(async (valid: boolean) => {
                if (valid) {
                    await this.$store.dispatch({
                        type: 'contact/create',
                        data: (this.$refs.form as any).getContact() as Contact
                    });
                    contactForm.resetFields();
                    this.$emit('save-success');
                    this.$emit('input', false);
                }
            })
        }
    }
