
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '../../../../lib/util'
    import AbpBase from '../../../../lib/abpbase'
    import CompanyContact from '../../../../store/entities/companycontext/companycontact'
    import KeyWordLookUpRequest from "@/store/entities/key-word-look-up-request";

    @Component
    export default class CompanyContactForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        @Prop({type: Boolean, default: false}) toEdit: boolean;
        @Prop({type: Boolean, default: false}) fromCompanySide: boolean;
        private isLoading: boolean = false;

        companyContact: CompanyContact = new CompanyContact();

        employmentTypes: any;
        departments: any;
        companies: any;
        contacts: any;
        types: any;

        data() {
            return {
                companies: [],
                contacts: [],
                employmentTypes: [],
                departments: [],
                types: []
            }
        };

        async contactChanged(val){
         if (val){
           let contact = await this.$store.dispatch({
             type: 'contact/get',
             data: val
           });
           this.companyContact.emailAddress = contact.emailAddress;
           this.companyContact.mobilePhone = contact.mobilePhone;
           this.$forceUpdate()
         }
        }

        async loadContacts(input) {
            this.isLoading = true;
            this.contacts = await this.$store.dispatch('contact/lookUp', new KeyWordLookUpRequest(input, this.companyContact.contactId));
            this.isLoading = false;
        }

        async loadData() {
            this.employmentTypes = await this.$store.dispatch('companycontact/getEmploymentTypes');
            this.departments = await this.$store.dispatch('department/lookUp');
            if (!this.fromCompanySide) {
                this.companies = await this.$store.dispatch('company/lookUp');
            }
            this.types = await this.$store.dispatch('companycontact/types');
        }

        async mounted() {
            await this.loadData();
        }

        async visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                this.companyContact = Util.extend(true, {}, this.$store.state.companycontact.editCompanyContact);
                if (this.fromCompanySide) {
                    await this.loadContacts('');
                }
            }
        }

        save() {
            (this.$refs.addressForm as any).validate(async (valid: boolean) => {
                if (valid) {
                    await this.$store.dispatch({
                        type: this.toEdit ? 'companycontact/update' : 'companycontact/create',
                        data: this.companyContact
                    });
                    (this.$refs.addressForm as any).resetFields();
                    this.$emit('save-success');
                    this.$emit('input', false);
                }
            })
        }

        cancel() {
            (this.$refs.addressForm as any).resetFields();
            this.$emit('input', false);
        }

        companyContactRule = {}
    }
