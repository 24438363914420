
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '@/lib/util'
    import AbpBase from '@/lib/abpbase'
    import PageRequest from '@/store/entities/page-request'
    import SortingModel from "@/store/entities/sortingmodel";
    import CompanyContactForm from "@/views/setting/companycontext/companycontact/CompanyContactForm.vue";
    import CompanyContactDto from "@/store/entities/companycontext/companycontact";
    import CreateContact from "@/views/setting/companycontext/contact/create-contact.vue";
    import ContactDto from "@/store/entities/companycontext/contact";


    class PageAddressRequest extends PageRequest {
        contactId: number;
        companyId: number;
    }

    @Component({
        components: {CompanyContactForm, CreateContact}
    })
    export default class CompanyContact extends AbpBase {
        @Prop({type: Number}) contactId = 0;
        @Prop({type: Number}) companyId = 0;
        @Prop({type: Boolean, default: false}) fromCompanySide: boolean;

        async edit(id: number) {
            this.$store.commit('companycontact/edit', await this.$store.dispatch({
                type: 'companycontact/get',
                data: id
            }));
            this.editModalShow = true;
        }

        createContact() {
            this.$store.commit('contact/edit', new ContactDto());
            this.createContactModalShow = true;
        }

        pagerequest: PageAddressRequest = new PageAddressRequest();

        createModalShow: boolean = false;
        editModalShow: boolean = false;
        createContactModalShow: boolean = false;

        async search() {
            await this.pageChange(1);
        }

        async changeSort(data) {
            if (this.pagerequest.sorting == null) {
                this.pagerequest.sorting = [];
            }
            let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
            if (item == null) {
                let sortingModel = new SortingModel();
                sortingModel.fieldName = data.key;
                sortingModel.isDescendingDirection = data.order === "desc";
                this.pagerequest.sorting.push(sortingModel)
            } else {
                item.isDescendingDirection = data.order === "desc";
            }
            await this.getpage()
        }

        get list() {
            return this.$store.state.companycontact.list;
        };

        get loading() {
            return this.$store.state.companycontact.loading;
        }

        async create() {
            let companyContact = new CompanyContactDto();
            companyContact.contactId = this.contactId;
            companyContact.companyId = this.companyId;
            this.$store.commit('companycontact/edit', companyContact);
            this.createModalShow = true;
        }

        async pageChange(page: number) {
            this.$store.commit('companycontact/setCurrentPage', page);
            await this.getpage();
        }

        async pagesizeChange(pagesize: number) {
            this.$store.commit('companycontact/setPageSize', pagesize);
            await this.getpage();
        }

        async getpage() {
            this.pagerequest.maxResultCount = this.pageSize;
            this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

            await this.$store.dispatch({
                type: 'companycontact/getAll',
                data: this.pagerequest
            })
        }

        get pageSize() {
            return this.$store.state.address.pageSize;
        }

        get totalCount() {
            return this.$store.state.address.totalCount;
        }

        get currentPage() {
            return this.$store.state.address.currentPage;
        }

        columns = [{
            title: this.L('Position'),
            key: 'position',
            sortable: 'custom'
        }, {
            title: this.L('EmploymentType'),
            key: 'employmentType'
        }, {
            title: this.contactId ? this.L('Company') : this.L('Contact'),
            key: this.contactId ? 'companyName' : 'contactName',
            sortable: 'custom'
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 150,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                await this.edit(params.row.id);
                            }
                        }
                    }, this.L('Edit')),
                    h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        },
                        on: {
                            click: async () => {
                                this.$Modal.confirm({
                                    title: this.L('Tips'),
                                    content: this.L('Delete company contact binding confirm'),
                                    okText: this.L('Yes'),
                                    cancelText: this.L('No'),
                                    onOk: async () => {
                                        await this.$store.dispatch({
                                            type: 'companycontact/delete',
                                            data: params.row
                                        })
                                        await this.getpage();
                                    }
                                })
                            }
                        }
                    }, this.L('Delete'))
                ])
            }
        }]

        async created() {
            this.pagerequest.contactId = this.contactId;
            this.pagerequest.companyId = this.companyId;
            await this.getpage();
        }
    }
